
import { defineComponent, reactive } from "vue";
import dateFormat from "@/utils/dateFormat";
export default defineComponent({
  name: "NeiItem",
  props: {
    neiorder: Object
  },
  
  setup() {
    return {
      dateFormat,
    };
  }
});
