
import { defineComponent, onMounted, inject, watch, ref } from "vue";
import dateFormat from "../../utils/dateFormat";
import axios from "axios";
export default defineComponent({
  name: "ClassItem",
  props: {
    order: Object
  },
  
  setup(props) {
    const userInfo = inject("userInfo") as any;
    const biaowuPriceOpen = ref()

    watch(
      () => {
        return props.order
      },
      (NewsVue) => {
        biaowuPriceOpen.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
      }
    )

    return {
      dateFormat,
      userInfo,
      biaowuPriceOpen
    };
  }
});
